<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Completed Marking - Online exam</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Completed Marking - Online exam
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="online_markers"
                      v-model="search.marked_by"
                      label="Select Marker"
                      item-text="full_name"
                      item-value="id"
                      clearable
                      :search-input.sync="onlineMarkerSearch"
                      v-on:keyup.enter="searchOngoingOnlineExam()"
                      outlined
                      dense
                      cache-items>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchOngoingOnlineExam"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Candidate name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Exam start date/time</th>
                  <th class="px-3">Mark Obtained</th>
                  <th class="px-3">Marked by</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in completed_online_marker" :key="index">
                    <td class="px-3">
                      <span   class="font-weight-bolder">
                        {{item.full_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 font-weight-medium text-hover-primary mb-1 font-size-sm">
                        {{ item.start_time_format }}
                      </a>
                    </td>
                    <td class="px-3">
                      <div class="mb-1" v-if="item.mark_obtained">
                        {{item.mark_obtained}}/{{item.availableMarks ? item.availableMarks :'100'}}
                      </div>
                      <div class="mb-1" v-else>
                        N/A
                      </div>
                    </td>
                    <td class="px-3">
                      {{item.marked_by_name ? item.marked_by_name : 'N/A'}}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openCandidateDialog(item.candidate_id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fas fa-eye</v-icon>
                                </span>
                                <span class="navi-text">Candidate details</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="completed_online_marker.length == 0">
                    <td colspan="6" class="text-center px-3">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="completed_online_marker.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="candidate_detail_dialog" width="600px">
      <v-card>
        <div class="card-body ">


          <div class="d-flex align-items-center">

            <div>
              <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-1">
                {{candidateDetail.full_name}}

              </a>
              <div class="font-weight-regular mb-1">
                <strong>Enrolment key</strong> : {{candidateDetail.exam_key}}
              </div>
              <div class="font-weight-regular mb-1">
                <strong>Enrolment pin</strong> : {{candidateDetail.exam_pin}}
              </div>
              <div class="font-weight-regular mb-1" v-html="'<strong>Exam status </strong> : &nbsp;'+candidateDetail.exam_status_badge">
                <strong>Exam status</strong> :
              </div>
              <div class="font-weight-regular mb-1" v-if="candidateDetail.gender">
                <strong>Gender</strong> : {{candidateDetail.gender}}
              </div>
            </div>
          </div>

        </div>

      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";
import CandidateService from "@/services/candidate/CandidateService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";

const onlineMarker=new OnlineMarkerService();
const candidate=new CandidateService();
const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  data(){
    return{
      search:{
        exam_key:'',
        marked_by:'',
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      isLoading:false,
      completed_online_marker:[],
      dialog:false,
      loading:false,

      remarks:'',
      total: null,
      perPage: null,
      page: null,
      candidate_detail_dialog:false,
      candidateDetail:{},
      online_markers:[],
      onlineMarkerSearch:'',
      menuStartDate:false,
      menuEndDate:false,
      onlineMarkerSearchLoading:false,
    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getCompletedOnlineExamResult(this.search, this.page)
          .then(response => {
            this.completed_online_marker=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOngoingOnlineExam(){
      this.page=1;
      this.getAllOngoingOfficialExams();
    },
    openCandidateDialog(candidateId){
      this.candidate_detail_dialog=true;
      this.getCandidateDetail(candidateId)
    },
    getCandidateDetail(getCandidateDetail){
      candidate
          .show(getCandidateDetail)
          .then(response => {
            this.candidateDetail=response.data.candidate;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },

  },
  mounted() {
    this.getAllOngoingOfficialExams();
  },
  watch:{
    onlineMarkerSearch(val){
      let data={
        name:val,
      }
      this.onlineMarkerSearchLoading=true;
      onlineMarker
          .search(data)
          .then((response) => {
            this.online_markers=response.data.onlineMarkers;
          })
          .catch(err => {

          })
          .finally(() => (this.onlineMarkerSearchLoading = false))
    },
  }
}
</script>